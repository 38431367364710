<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Centro de costo </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12" sm="6">
            <CentroCostoSelect
              :rules="reglas.requerido"
              :outlined="true"
              :dense="true"
              v-model="datos.id_centro_costo"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <CuentaContableSelect
              ref="CuentaContableSelectRef"
              :clearable="true"
              @change="consultarCodigo"
              :outlined="true"
              :dense="true"
              v-model="datos.id_cuenta_contable_padre"
              :filtro="{ind_cuenta_detalle : false}"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="datos.codigo"
              readonly
              :rules="reglas.requerido"
              outlined
              dense
              label="Codigo"
            ></v-text-field>
         
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field v-model="datos.nombre" :rules="reglas.requerido" outlined dense label="Nombre"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field v-model="datos.descripcion" outlined dense label="Descripcion"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-switch v-model="datos.ind_cuenta_detalle" :label="`Cuenta de detalle`"></v-switch>
          </v-col>
          <v-col cols="12">
              <v-row v-if="ViewTrew">
                <v-btn icon small @click="ViewTrew = !ViewTrew"
                  ><v-icon>{{ icons.mdiInformation }}</v-icon></v-btn
                >
                {{ datos.jerarquia }}>>{{ datos.nombre.toUpperCase() }}
              </v-row>
              <v-row v-else>
                <v-btn icon small @click="ViewTrew = !ViewTrew"
                  ><v-icon>{{ icons.mdiInformation }}</v-icon></v-btn
                >
                <v-treeview ref="treeviewRef" open-all :items="jerarquiaTrewValor"></v-treeview>
              </v-row>
            </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-divider></v-divider>
        <v-row>
          <v-col class="text-right mt-4">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import { mdiInformation, mdiDelete } from '@mdi/js'
import ContabilidadService from '@/api/servicios/ContabilidadService'
import goTo from 'vuetify/lib/services/goto'
import CentroCostoSelect from '../../centro-costo/componentes/Select.vue'
import CuentaContableSelect from './Select.vue'
export default {
  components: {
    CentroCostoSelect,
    CuentaContableSelect,
  },
  setup(props, context) {
    const CuentaContableSelectRef = ref(null)
    const guardando = ref(false)
    const form = ref(null)
    const datosInit = {
      id: -1,
      id_cuenta_contable_padre: null,
      id_cuenta_contable_principal: null,
      id_centro_costo: null,
      codigo: '',
      id_nivel: 1,
      nombre: '',
      descripcion: '',
      ind_activo: true,
      ind_cuenta_detalle: false,
      jerarquia: '',
    }
    const ViewTrew = ref(false)
    const jerarquiaTrewValor = ref([])
    const jerarquiaTrew = () => {
      const trew = datos.value.jerarquia.split('>>')
      let trewTem = []
      let children = []
      let indexT = 0
      trew.forEach((element, index) => {
        indexT = index
        if (index == 0) {
          trewTem.push({
            id: index,
            name: element,
            children: [],
          })
          children = trewTem[0].children
        } else {
          children.push({
            id: index,
            name: element,
            children: [],
          })
          children = children[0].children
        }
      })

      
     

      jerarquiaTrewValor.value = trewTem
      setTimeout(() => {
        abrirTodo()
      }, 100)
    }

    const treeviewRef = ref(null)

    const abrirTodo = () => {
      treeviewRef.value.updateAll(true)
    }

    const datos = ref(JSON.parse(JSON.stringify(datosInit)))
    const limpiar = () => {
      datos.value = JSON.parse(JSON.stringify(datosInit))
      consultarCodigo()
    }
    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
    }
    const cargandos = ref(false)

    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }
    onBeforeMount(() => {
      limpiar()
    })
    const cargandoCodigo = ref(false)
    const consultarCodigo = item => {
      cargandoCodigo.value = true
      try {
        ContabilidadService.cuentaContableCodigoConsultar({ id_cuenta_contable: item })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              if (datos.value.id < 0) {
                datos.value.codigo = response.data.codigo
                datos.value.id_nivel = response.data.id_nivel
                datos.value.jerarquia = response.data.jerarquia
                jerarquiaTrew()
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoCodigo.value = false
          })
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: error,
        })
        cargandoCodigo.value = false
      }
    }

    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          let _jerarquia = ''

          _jerarquia = datos.value.jerarquia + '>>' + datos.value.nombre

          ContabilidadService.cuentaContableNuevo({
            ...datos.value,
            id_empleado: store.state.user.id_empleado,
            jerarquia: _jerarquia,
          })
            .then(response => {
              console.log(response)
              if (response.data.mensaje == 'GUARDADA CON EXITO') {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista()
                limpiar()
                CuentaContableSelectRef.value.cargarDatos()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}: `,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
                error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'Guardar',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          store.commit('setAlert', {
            message: error,
            type: 'error',
                error: error,
                funcion: 'Guardar',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }
    const CargarEditar = item => {
      limpiar()

      const jerarquiaTemp = item.jerarquia.split('>>')
      let _jerarquia = item.jerarquia + '>>'
      _jerarquia = _jerarquia.replace('>>' + jerarquiaTemp[jerarquiaTemp.length - 1] + '>>', '')

      datos.value = JSON.parse(JSON.stringify({ ...item, jerarquia: _jerarquia }))
      jerarquiaTrew()
      setTimeout(() => {
        abrirTodo()
      }, 100)
      goTo(0)
    }
    return {
      reglas,
      datos,
      cargandos,
      Guardar,
      form,
      ActualizarLista,
      icons: {
        mdiInformation,
        mdiDelete,
      },
      limpiar,
      CargarEditar,
      cargandoCodigo,
      consultarCodigo,
      CuentaContableSelectRef,
      jerarquiaTrew,
      abrirTodo,
      treeviewRef,
      jerarquiaTrewValor,
      ViewTrew,
    }
  },
}
</script>