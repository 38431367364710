<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Otros Movimientos </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col>
                <SelectGrupoNegocioValor 
                :SelectDefault="1" 
                :todos="false" 
                 :dense="true"
                 :outlined="true"
                v-model="datos.id_grupo_negocio"
                >
                </SelectGrupoNegocioValor>
             
              </v-col>
              <v-col cols="12">
                <DatePicker
                  :rules="reglas.requerido"
                  v-model="datos.fecha_movimiento"
                  :dense="true"
                  :outlined="true"
                  :label="'Fecha'"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  outlined
                  dense
                  :items="tiposMovimientos"
                  label="Tipo Movimiento"
                  item-text="descripcion"
                  item-value="id"
                  hide-details="auto"
                  :rules="reglas.requerido"
                  v-model="datos.id_tipo_movimiento"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <CuentaContableSelect
                  :clearable="true"
                  :outlined="true"
                  :dense="true"
                  :add="false"
                  v-model="datos.id_cuenta_contable"
                  ref="datos_id_cuenta_contable"
                  :rules="reglas.requerido"
                  :filtro="{ ind_cuenta_detalle: true }"
                />
              </v-col>
              <v-col cols="12">
                <SelectMonedaJson
                  :clearable="true"
                  :outlined="true"
                  :dense="true"
                  :add="false"
                  v-model="datos.moneda"
                  :rules="reglas.requerido"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  :rules="reglas.requerido"
                  hide-details="auto"
                  dense
                  outlined
                  item-text="nombre"
                  item-value="id"
                  :items="tiposPagos"
                  label="Tipo Pago"
                  v-model="datos.id_tipo_pago"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <SelectCuentaBancaria
                  :label="'Cuenta'"
                  :padres="{
                    id_moneda: datos.moneda.id,
                    id_grupo_negocio: datos.id_grupo_negocio,
                    id_tipo_caja_banco: tipo_caja_banco,
                  }"
                  :rules="reglas.requerido"
                  v-model="datos.id_cuenta_bancaria"
                  :dense="true"
                  :outlined="true"
                  :add="false"
                ></SelectCuentaBancaria>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
           
              <v-col cols="12">
                <BuscarProveedor
                  ref="BuscarProveedorRef"
                  :label="`Proveedor`"
                  class="mb-4"
                  @GetProveedor="GetProveedor"
                  :rules="reglas.requerido"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details="auto"
                  v-model="datos.monto_moneda"
                  :rules="reglas.requerido"
                  outlined
                  dense
                  label="Monto Moneda"
                  @keyup="calcularModena('moneda')"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="!datos.moneda.actual">
                <v-text-field
                  hide-details="auto"
                  v-model="datos.tasa"
                  :rules="reglas.requerido"
                  outlined
                  dense
                  label="Tasa"
                  @keyup="calcularModena('moneda')"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="!datos.moneda.actual">
                <v-text-field
                  hide-details="auto"
                  v-model="datos.monto_real"
                  :rules="reglas.requerido"
                  outlined
                  dense
                  label="Monto $"
                  @keyup="calcularModena('real')"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  :rules="reglas.requerido"
                  v-model="datos.descripcion"
                  outlined
                  dense
                  label="Descripcion"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-divider></v-divider>
        <v-row>
          <v-col class="text-right mt-4">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import { mdiInformation, mdiDelete } from '@mdi/js'
import ContabilidadService from '@/api/servicios/ContabilidadService'
import goTo from 'vuetify/lib/services/goto'
import DatePicker from '@/components/DatePicker.vue'
import CuentaContableSelect from '@/views/sistema/contabilidad/configuracion/cuenta-contable/componentes/Select.vue'
import SelectTipoPago from '@/views/sistema/administracion/banco-caja/tipo-pago/components/Select.vue'
import SelectMonedaJson from '@/components/SelectMonedaJson.vue'
import SelectCuentaBancaria from '@/views/sistema/administracion/banco-caja/cuenta-bancaria/components/Select.vue'
import funciones from '@/funciones/funciones'
import SelectGrupoNegocioValor from '@/components/SelectGrupoNegocioValor.vue'
import BuscarProveedor from '@/views/sistema/puesto/compra/proveedor/componentes/BuscarProveedor.vue'
export default {
  watch: {
    'datos.moneda': function (_new, _old) {
      if (_new != _old && _new.tipo_pago) {
        this.tiposPagos = _new.tipo_pago.filter(it => it.ind_pago_compras)
        this.datos.tasa = _new.tasa
        
      }
    },
    'datos.id_tipo_pago': function (_new, _old) {
      if (_new != _old) {
        const x = this.tiposPagos.find(it => it.id == _new)
        if (x) {
          this.tipo_caja_banco = x.id_tipo_caja_banco
        }
      }
    },
  },
  mounted() {
    this.limpiar()
  },
  components: {
    DatePicker,
    SelectTipoPago,
    SelectMonedaJson,
    CuentaContableSelect,
    SelectCuentaBancaria,
    SelectGrupoNegocioValor,
    BuscarProveedor
  },
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const tiposPagos = ref([])
    const tipo_caja_banco = ref(null)
    const tiposMovimientos = [
      {
        id: 1,
        descripcion: 'ACREADOR',
      },
      {
        id: 2,
        descripcion: 'DEUDOR',
      },
    ]
    const datosInit = {
      id_grupo_negocio: 1,
      id: -1,
      nombre: '',
      descripcion: '',
      ind_activo: true,
      moneda: [],
      monto_moneda: '',
      tasa: '',
      monto_real: '',
      fecha_movimiento: '',
      id_tipo_movimiento: '',
      id_cuenta_contable : '',
      id_cuenta_bancaria   : '',
      id_tipo_pago:'',
      id_estado: 93,
      id_proveedor : null,
      proveedor : null
    }
    const BuscarProveedorRef = ref(null)
    const datos = ref(JSON.parse(JSON.stringify(datosInit)))
    const limpiar = () => {
      datos.value = JSON.parse(JSON.stringify(datosInit))
      form.value?.reset()
      //BuscarProveedorRef.value.model = null
    }
    const GetProveedor = item => {
      console.log('GetProveedor', item)
      datos.value.proveedor = item
      datos.value.id_proveedor = item.id
    }
    
    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
    }
    const cargandos = ref(false)

    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }
    const Guardar = () => {
      guardando.value = true

      if (validarForm()) {
        
        try {
          ContabilidadService.otrosMovimientosActualizar({ ...datos.value, id_proveedor : datos.value.proveedor.id,  id_empleado: store.state.user.id_empleado })
            .then(response => {
              console.log(response)
              if (response.data.mensaje == 'GUARDADA CON EXITO') {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}: `,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
              error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'Guardar',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          store.commit('setAlert', {
            message: error,
            type: 'error',
              error: error,
                funcion: 'Guardar',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }
    const CargarEditar = item => {
      limpiar()
      datos.value = JSON.parse(JSON.stringify(item))
      goTo(0)
    }
    const calcularModena = tipo => {
      if ((tipo == 'moneda')) {
        datos.value.monto_real = funciones.roundTo(datos.value.monto_moneda   / datos.value.tasa,4)
      } else if ((tipo =='real')) {
        datos.value.monto_moneda = funciones.roundTo(datos.value.monto_real  * datos.value.tasa,4) 
      }
    }

    return {
      reglas,
      datos,
      cargandos,
      Guardar,
      form,
      ActualizarLista,
      icons: {
        mdiInformation,
        mdiDelete,
      },
      limpiar,
      CargarEditar,
      tiposMovimientos,
      tiposPagos,
      tipo_caja_banco,
      calcularModena,
      GetProveedor,
      BuscarProveedorRef
    }
  },
}
</script>