<template>
  <span>
    <v-btn v-if="tipoBtn == 1" color="info" small @click="CargarDatos()" :loading="cargandoDatos" :disabled="!id">
      <v-icon left small>
        {{ icons.mdiFilePdfBox }}
      </v-icon>
      PDF
    </v-btn>

    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <a class="pr-1" v-bind="attrs" v-on="on" @click="CargarDatos()">
          <v-icon color="primary">{{ icons.mdiFilePdfBox }}</v-icon>
        </a>
      </template>
      <span>PDF</span>
    </v-tooltip>
    <Pdf ref="pdfRef"></Pdf>
    <v-dialog v-model="dialogTipoPrint" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Tipo de moneda </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-card class="d-flex align-center primary" height="100" @click="GetPdf(false)">
                <v-scroll-y-transition>
                  <div class="flex-grow-1 text-center white--text">Moneda Factura</div>
                </v-scroll-y-transition>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="d-flex align-center primary" height="100" @click="GetPdf(true)">
                <v-scroll-y-transition>
                  <div class="flex-grow-1 text-center white--text">Moneda Principal</div>
                </v-scroll-y-transition>
              </v-card>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="dialogTipoPrint = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import ContabilidadService from '@/api/servicios/ContabilidadService'
import { mdiFilePdfBox } from '@mdi/js'
import { ref } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import Pdf from '@/components/PdfFormatoLibreDocumentos.vue'
export default {
  props: {
    id: Number,
    tipoBtn: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Pdf,
  },
  setup(props) {
    const dialogTipoPrint = ref(false)
    const cargandoDatos = ref(false)
    const pdfRef = ref(null)
    let datoInit = {}
    let verReal = false

    const monedaSimbolo = () => {
      let simbolo = '$'
      if (datoInit.moneda && !verReal) {
        simbolo = datoInit.moneda.simbolo
      }
      return simbolo
    }
    const monedaValor = _dato => {
      let valor = _dato
      if (datoInit.moneda && !verReal) {
        valor = valor * datoInit.tasa
      }
      return valor
    }
    const monedaValorReal = _dato => {
      let valor = _dato
      if (datoInit.moneda) {
        valor = valor / datoInit.tasa
      }
      return valor
    }

    const colorEstatus = item => {
      switch (item) {
        case 0:
          return '#FF0000'
          break
        case 63:
          return '#FF0000'
          break
        case 64:
          return '#003CCF'
          break
        case 65:
          return '#00C806'
          break
        default:
          return '#B2B2B2'
          break
      }
    }
    const CargarDatos = () => {
      ContabilidadService.otrosMovimientosListar({ id: props.id })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datoInit = response.data.datos[0]
            GetPdf()
            /*if (datoInit.moneda.actual == true) {
              
            } else {
              dialogTipoPrint.value = true
            }*/
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargandoDatos.value = false
        })
    }

    const GetPdf = _valor => {
      verReal = _valor

      dialogTipoPrint.value = false
      const cuerpoTablaDetalles = []

      cuerpoTablaDetalles.push([
        datoInit.cuenta_contable.codigo,
        datoInit.cuenta_contable.nombre,

        '$.' + FuncionesGenerales.formatoNumeric(datoInit.monto_real),
      ])

      const datosInit = {
        cabecera1: { visible: true },
        nombreDocumento: `Otros Movimientos: ${datoInit.id.toString()}`,
        etiqueta: {
          visible: false,
        },
        numeroDocumento: {
          texto: 'Otro Movimientos: ',
          valor: datoInit.id.toString(),
        },
        numeroControl: {
          texto: 'Doc Nro',
          valor: 'MOV-' + datoInit.id.toString(),
        },
        numeroFecha: {
          texto: 'Fecha',
          valor: FuncionesGenerales.formatoFecha(datoInit.fecha_movimiento),
        },
        sub1: {
          linea1: [
            { texto: 'Proveedor:', valor: datoInit.proveedor.nombre },
            { texto: 'Rif:', valor: datoInit.proveedor.documento },
            { texto: 'Telf:', valor: datoInit.proveedor.telefono },
            { texto: 'Direccion:', valor: datoInit.proveedor.direccion },
          ],
          linea2: [],
        },
        sub2: {
          linea1: [
            {
              texto: 'Grupo Administrativo:',
              valor: datoInit.grupo_negocio.descripcion,
            }, 
            { texto: 'Tasa:', valor: datoInit.tasa.toString() },
            { texto: 'Estado:', valor: datoInit.estado.descripcion },
            { texto: 'Moneda:', valor: datoInit.moneda.nombre },
          ],
          linea2: [
            { texto: 'Fecha Creacion:', valor: FuncionesGenerales.formatoFecha(datoInit.fecha_creacion, 1) },
            {
              texto: 'Hora Creacion:',
              valor: FuncionesGenerales.formatoFecha(datoInit.fecha_creacion, 2)
                .replace(/\u00a0/g, '')
                .replace(/\u202f/g, ''),
            },
          ],
        },

        pie: {
          nota: {
            texto: 'Descripcion:',
            valor: datoInit.descripcion,
          },
          totales: [
            {
              texto: 'Total:',
              valor: '$.' + (datoInit.monto_real).toFixed(2),
            },
          ],
        },

        detalles: {
          cabecera: [
            { texto: 'Codigo', posicion: '45', alineacion: 'left' },
            {
              texto: 'cuenta Contable',
              posicion: '100',
              alineacion: 'left',
              justificar: { maxWidth: 130, align: 'justify', maximoItems: 33 },
            },
            { texto: 'Total', posicion: '555', alineacion: 'right' },
          ],
          cuerpo: cuerpoTablaDetalles,
        },
      }

      if (datoInit.estado == 35) {
        datosInit.sub2.linea1.push({
          texto: 'Empleado Anula:',
          valor: datoInit.empleado_anula_json.nombre + ' ' + datoInit.empleado_anula_json.apellido,
        })
        datosInit.sub2.linea2.push({
          texto: 'Fecha Anulacion:',
          valor: FuncionesGenerales.formatoFecha(datoInit.fecha_anulacion, 2),
        })
      }

      console.log('datosInit', datosInit)
      pdfRef.value.generarPDF(datosInit)
    }

    return {
      GetPdf,
      cargandoDatos,
      pdfRef,
      dialogTipoPrint,
      icons: {
        mdiFilePdfBox,
      },
      CargarDatos,
    }
  },
}
</script>