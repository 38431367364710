
<template>
  <div>
    <v-card>
      <v-card-subtitle>Listar</v-card-subtitle>
      <v-card-text class="text-right">
        <v-btn small class="primary" @click="cargar()">Buscar</v-btn>
      </v-card-text>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ID</th> 
                <th class="text-left">Proveedor</th>
                <th class="text-left">Fechas</th>
                <th class="text-left">Grupo Administraion</th>
                <th class="text-left">Descripcion</th>
                <th class="text-left">Cuenta Contable</th>
                <th class="text-left">Tipo Pago</th>
                <th class="text-left">Estado</th>
                <th class="text-right" >Monto</th>
                <th class="text-center">Accion</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in Lista" :key="item.id">
                <td>{{ item.id }}</td>
                <td>
                 <small>{{ item.proveedor.documento }}</small>  <br>
                 <small>{{ item.proveedor.nombre }}</small> 
                </td>
                <td>
                  <small>    Fecha Movimiento: {{ funciones.formatoFecha(item.fecha_movimiento )  }} </small>   <br>
                  <small>Fecha Creacion: {{ funciones.formatoFecha(item.fecha_creacion) }}</small>
                </td>
                <td>{{ item.grupo_negocio.descripcion }}</td>
                <td>{{ item.descripcion }}</td>
                <td>
                  {{ item.cuenta_contable.nombre }} <br>
                  {{ item.tipo_movimiento.descripcion }} 
                </td>
                <td>
                  {{ item.tipo_pago.nombre }} <br>
                  {{ item.moneda.nombre }} 
                </td>
                <td>{{ item.estado.descripcion }}</td>
                <td class="text-right">
                  {{ item.moneda.simbolo }}  {{ item.monto_moneda }} <br>
                 <small>$ {{ item.monto_real }} </small>  
                </td>
                <td class="text-center">
                  
                  <v-btn icon> 
                    <v-icon color="success"  >{{ icons.mdiCheck }}</v-icon> 
                  </v-btn>
                  <v-btn icon>  
                    <v-icon color="error"  >{{ icons.mdiCancel }}</v-icon> 
                  </v-btn>
                  <BtnPdfMovimientos  :id="item.id" :tipoBtn="2"  />
                </td>
                
                   
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <!-- Table -->
    </v-card>
  </div>
</template>
  
<script>
import { onBeforeMount, ref, context, watch } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import ContabilidadService from '@/api/servicios/ContabilidadService'
import funciones from '@/funciones/funciones'
import BtnPdfMovimientos from './BtnPdfMovimientos.vue'
export default {
  components: {BtnPdfMovimientos},
  setup(props, context) {
    const search = ref('')
    const Lista = ref([])
    const ListaLoad = ref(false)
    const cargandoDatos = ref(false)
    const filtroInit = {
     id:-1
    }
    const filtro = ref(JSON.parse(JSON.stringify(filtroInit)))
    const GetEditar = item => {
      console.log(item)
      context.emit('GetEditar', { ...item })
    }
    onBeforeMount(() => {
      cargar()
    })
   
    const cargar = () => {
      cargandoDatos.value = true
      try {
        ContabilidadService.otrosMovimientosListar()
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              Lista.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: error,
        })
        cargandoDatos.value = false
      }
    }
    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
      },
      search,
      headers: [
        { text: 'Id', value: 'id' }, 
        { text: 'Descripcion', value: 'descripcion' },
        { text: 'Cuenta Contable', value: 'cuenta_contable.nombre' },
        { text: 'Accion', value: 'accion', sortable: false },
      ],
      Lista,
      ListaLoad,
      cargar,
      cargandoDatos,
      GetEditar,
      funciones
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  