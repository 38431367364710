<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Centro de costo </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field v-model="datos.nombre" :rules="reglas.requerido" outlined dense label="Nombre"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field v-model="datos.descripcion" outlined dense label="Descripcion"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-divider></v-divider>
        <v-row>
          <v-col class="text-right mt-4">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import { mdiInformation, mdiDelete } from '@mdi/js'
import ContabilidadService from '@/api/servicios/ContabilidadService'
import goTo from 'vuetify/lib/services/goto'
export default {
  components: {},
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const datosInit = {
      id: -1,
      nombre: '',
      descripcion: '',
      ind_activo: true,
    }
    const datos = ref(JSON.parse(JSON.stringify(datosInit)))
    const limpiar = () => {
      datos.value = JSON.parse(JSON.stringify(datosInit))
    }
    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
    }
    const cargandos = ref(false)

    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }
    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          ContabilidadService.centroCostoNuevo({ ...datos.value,id_empleado : store.state.user.id_empleado })
            .then(response => {
              console.log(response)
              if (response.data.mensaje == 'GUARDADA CON EXITO') {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}: `,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error', 
                error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'guardar',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          store.commit('setAlert', {
            message: error,
            type: 'error',
                error: error,
                funcion: 'guardar',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }
    const CargarEditar = item => {
      limpiar() 
      datos.value = JSON.parse(JSON.stringify(item))
      goTo(0)
    }
    return {
      reglas,
      datos,
      cargandos,
      Guardar,
      form,
      ActualizarLista,
      icons: {
        mdiInformation,
        mdiDelete,
      },
      limpiar,
      CargarEditar
    }
  },
}
</script>